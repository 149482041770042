import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import styled from "styled-components";
import { EvaluationNew } from "../../Interface/ApiInterface";
import Close from "../../assets/icons/CloseModal.svg";
import {
  ModalBodyTable,
  ModalContainerBodySelect,
  ModalHeadTable,
} from "../../components/atoms/EvaluationForms/EvaluationFormsStyles";
import { ColumnsDataD } from "../../components/atoms/KpisTables/inputMask/ModalInputGeneral";
// import ModalInputSelect from "../../components/atoms/KpisTables/inputMask/ModalInputSelect";
import { TextField } from "@mui/material";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import Caution from "../../components/organisms/ConfirmationModals/Caution";
import {
  EvaluationContext,
  EvaluationContextType,
} from "../../context/EvaluationContext";
import { useCollaboratorRedux } from "../../redux/Collaborators";
import { useEvaluationRedux } from "../../redux/Evaluations";
import { useObjetives } from "../../redux/Objetives";
import { getObjectivesPreviewListFuture } from "../../services/dataSelects.service";
import { ContainerDescription } from "./Dashboard/stylesDashboard/AdminStyles";
import ModalEditObjective from "./ModalActions/ModalEditObjective";
import { ColumnsDataG } from "./components/ColumnsData";
import EditObjetive from "./components/EditObjetive";
import FooterModalMySelect from "./components/FooterModalMySelect";
import ModalObjetive from "./components/ModalObjetive";
interface ValueType {
  semester: string;
  value: string;
  label: string;
}

interface TableData {
  semestre: number;
  valor: number;
  descripcion: ValueType;
  meta: number;
}

interface ModalTableProps {
  show: boolean;
  onHide: any;
  evaluation: EvaluationNew;
  semestre?: any;
  objetivos?: any;
}

const options = [
  { value: "0", label: "Selecciona un objetivo", semester: "0" },
  { value: "0", label: "Selecciona un objetivo", semester: "0" },
  { value: "0", label: "Selecciona un objetivo", semester: "0" },
  { value: "0", label: "Selecciona un objetivo", semester: "0" },
];
const regexValidateNumber = /^[+-]?((\d+(\.\d*)?)|(\.\d+))$/;
const ModalTable = ({
  show,
  onHide,
  evaluation,
  semestre,
  objetivos,
}: ModalTableProps) => {

  const { setLoadingPagination } = useContext(
    EvaluationContext
  ) as EvaluationContextType;

  //  console.log("Evaluación", evaluation)
  const validateCantidad = (objetivosSelect: any) => {

    const data = [];

    for (let i = 0; i < objetivosSelect.length; i++) {
      data.push({
        semestre: 0,
        valor: 0,
        descripcion: options[i],
        meta: 0,
      });
    }

    setData(data);
  };

  const { setFutureObjetives, evaluationInfo, periodId, setIsReduxOrContext } =
    useContext(EvaluationContext) as EvaluationContextType;

  //! ------------- Se define la cantidad de opciones a imprimir -------------
  useEffect(() => {
    // console.log("Se ejecuta este useEffect");
    const objetivosSelect = objetivos.filter((item: any) => item.type === "I");

    validateCantidad(objetivosSelect);

    const objetivosGlobales = objetivos.filter(
      (objetivo: any) => objetivo.type === "G"
    );

    // console.log("objetivos globales -> ", objetivosGlobales)
    setObjetivesTypeG(
      objetivosGlobales.map((objetivo: any) => {
        const obj = {
          id: objetivo?.id,
          label: objetivo?.description,
          valor: objetivo?.valor,
        };
        return obj;
      })
    );
  }, [objetivos]);
  //! ------------- Se define la cantidad de opciones a imprimir -------------

  const { handleSetIndividualObjetives, Evaluation } = useEvaluationRedux();
  const { individualObjetives, handleGetAllIndividualObjetives } =
    useObjetives();

  const [lastValue, setLastValue] = useState(5);
  const { handleGetCollaborator } = useCollaboratorRedux();
  const [suma, setSuma] = useState(false);
  const [btn, setBtn] = useState(true);
  const [indexObjetive, setIndexObjetive] = useState(0);
  const [showModalCaution, setShowModalCaution] = useState(false);

  const [optionsUsed, setOptionsUsed] = useState<any[]>([]);

  //** Modal States
  //** Editar modal
  const [showEditObjetive, setShowEditObjetive] = useState(false);

  const [optionsData, setOptionsData] = useState<ValueType[]>([]);

  const [data, setData] = useState<any[]>([]);
  const [copyData, setCopyData] = useState<any>([]);

  const [modalEditObjetive, setModalEditObjetive] = useState(false);
  const [modalInfo, setModalInfo] = useState({});

  const [indexEdit, setIndexEdit] = useState(0);
  const [objetivesTypeG, setObjetivesTypeG] = useState<any[]>([]);

  const navigate = useNavigate();

  const onClose = (e: any) => {
    e.preventDefault();

    const objetivosSelect = objetivos.filter((item: any) => item.type === "I");
    validateCantidad(objetivosSelect);
    onHide(false);
    setLastValue(5);
  };

  const handleChange = (
    index: number,
    field: keyof TableData,
    value: any,
    editObjetiveModal?: boolean
  ) => {
    const newData = [...data];


    // console.log("Hola", field, newData[index])
    if (newData[index].semestre === undefined) {
      newData[index].semestre = value.semester;
    }

    // if(field === "meta"){
    //   newData[index][field] = value;
    //   setData(newData);
    //   setIndexObjetive(index);
    // }
    newData[index][field] = value;
    setData(newData);
    setIndexObjetive(index);

    //! ------------- LÓGICA DE DESAPARECER DATOS YA SELECCIONADOS -------------

    const datosss = optionsUsed.filter(
      (item) => item.value !== newData[index].descripcion.value
    );

    //** Estos son los datos que ya hemos seleccionado
    const newStates = optionsUsed.filter((elemento1) => {
      return data.some((elemento2) => {
        return elemento2.descripcion.value === elemento1.value;
      });
    });

    const array1Distinct = datosss.filter((elemento) => {
      return !newStates.some((elem) => elem.value === elemento.value);
    });

    setOptionsData(array1Distinct);

    //! -------------  LÓGICA DE DESAPARECER DATOS YA SELECCIONADOS  -------------

    if (editObjetiveModal) {
      setModalInfo(value);
      setBtn(false);
    }

    if (field === "descripcion" && !editObjetiveModal) {
      // console.log("<TERCERA PARTE> --> Entro acá", value);

      setModalEditObjetive(true);
      setModalInfo(value);
      setBtn(false);
    }
  };

  //** Este onSubmit esta editado para mandar la info seteada como lo pide el back
  const onSubmit = async () => {
    const dataSaved = data.map((item) => {
      return {
        id: +item.descripcion.value,
        valor: item.valor === "" ? 0 : item.valor,
        meta: item.meta === "" ? 0 : item.meta,
        description: item.descripcion.label,
        type: "I",
      };
    });

    let dataMandar = [
      ...objetivos.filter((item: any) => {
        return item.type !== "I" && item.type !== "D";
      }),
    ];

    let dataTypeG = dataMandar.map((obj: any, index: number) => {
      const newObj = {
        ...obj,
        valor: objetivesTypeG[index].valor,
      };

      return newObj;
    });

    let dataMandarDos = [
      ...objetivos.filter((item: any) => {
        return (
          item.type !== "I" &&
          item.type !== "G" &&
          item.description !==
          "Desempeño laboral, humano, actitud y habilidades"
        );
      }),
    ];
    let dataMandarTres = [
      ...objetivos.filter((item: any) => {
        return (
          item.type !== "I" &&
          item.type !== "G" &&
          item.description ===
          "Desempeño laboral, humano, actitud y habilidades"
        );
      }),
    ];

    dataMandarTres[0].valor = lastValue;

    const arregloDataUnida = [
      ...dataTypeG,
      ...dataSaved,
      ...dataMandarDos,
      ...dataMandarTres,
    ];

    // console.log("Esta es la data que se enviará: ", arregloDataUnida);

    handleGetCollaborator(evaluation.employeeEvaluationEvaluatedUserId);
    handleSetIndividualObjetives(
      evaluation.employeeEvaluationId,
      arregloDataUnida
    );

    // console.log("<EVALUACIÓN>: ", Evaluation);

    const { data: listFuture } = await getObjectivesPreviewListFuture(
      periodId,
      evaluation.employeeEvaluationEvaluatedUserId,
      evaluation.employeeEvaluationId
    );
    if (listFuture.length > 0) {
      setIsReduxOrContext(true);
      setFutureObjetives(listFuture);
    }
  };

  useEffect(() => {
    const query = "?PageSize=10000&PageNumber=1";
    handleGetAllIndividualObjetives(query);
  }, [objetivos]);

  useEffect(() => {
    if (individualObjetives && optionsData?.length === 0) {
      const newData = individualObjetives.map((item: any, index: number) => {
        return {
          value:
            item.generalObjetivesId !== undefined
              ? `${item.generalObjetivesId}`
              : "",
          label: item.objetiveName !== undefined ? `${item.objetiveName}` : "",
          semester:
            item.generalObjetivesSemester !== undefined &&
              item.generalObjetivesSemester === 1
              ? `2`
              : "1",
        };
      });

      setOptionsData(newData);
      setOptionsUsed(newData);
    }
  }, [individualObjetives]);

  const objetivosSum = objetivos.filter(
    (item: any) =>
      item.type !== "I" &&
      item.type !== "G" &&
      item.description !== "Desempeño laboral, humano, actitud y habilidades"
  );
  const [validationMeta, setValidationMeta] = useState(true);
  useEffect(() => {
    const updatedData = data.map((currentObj) => {

      const hasChanged =
        currentObj.valor !== 0 &&
        currentObj.valor !== null &&
        currentObj.valor !== "" &&
        currentObj.valor !== "0" &&
        currentObj.meta !== null &&
        currentObj.meta !== "0" &&
        Number(currentObj.meta) >= 1 &&
        currentObj.meta !== "" &&
        currentObj.meta !== 0 &&
        currentObj.descripcion.label !== "Selecciona un objetivo";

      const nothingChanged =
        (currentObj.valor === 0 ||
          currentObj.valor === null ||
          currentObj.valor === "" ||
          currentObj.valor === "0") &&
        (currentObj.meta === 0 ||
          currentObj.meta === null ||
          currentObj.meta === "" ||
          currentObj.meta === "0" ||
          Number(currentObj.meta) >= 1) &&
        currentObj.descripcion.label === "Selecciona un objetivo";
      // console.log("currentObj", Number(currentObj.meta), hasChanged, nothingChanged);
      if (hasChanged) {
        return hasChanged;
      }
      if (nothingChanged) {
        return nothingChanged;
      }
      return hasChanged;
    });

    const updatedDataMeta = data.map((currentObj) => {
      // console.log("currentObj.meta", currentObj.meta, typeof currentObj.meta);

      // Determinamos si `meta` ha cambiado.
      // Considera que ha cambiado si es 0, "" o "0".
      const hasChanged = currentObj.meta === 0 || currentObj.meta === "" || currentObj.meta === "0";

      // console.log("currentObj.meta", hasChanged);

      // Si `meta` es exactamente 0, retornamos `false`.
      if (currentObj.meta === 0) {
        return false;
      }

      // De otro modo, retornamos si ha cambiado o no.
      return hasChanged;
    });

    // console.log("Cambiaste", updatedDataMeta);

    const valuesDataToNumber = data
      .map((item: any) => Number(item.valor))
      .reduce((a, b) => a + (b || 0), 0);
    const desempenioValueToNumber = Number(lastValue);
    const objetivesTypeGOnlyNumbers = objetivesTypeG
      .map((obj: any) => Number(obj.valor))
      .reduce((a, b) => a + (b || 0), 0);

    let suma =
      valuesDataToNumber +
      objetivosSum.reduce((a: any, b: any) => a + (b.valor || 0), 0) +
      desempenioValueToNumber +
      objetivesTypeGOnlyNumbers;


    setCopyData(updatedData);
    const validateCurrentObjetives = () => {
      if (updatedData.includes(false)) {
        setBtn(true);
      } else {
        setBtn(false);
        if (suma === 100) {
          setSuma(false);
          setBtn(false);
        } else {
          setSuma(true);
          setBtn(true);
        }
      }
    };
    validateCurrentObjetives();

    const validateMetaOfCurrentObjetives = () => {
      if (updatedDataMeta.includes(true)) {
        // console.log("No se puede guardar")
        // setBtn(true);
        setValidationMeta(false)
      } else {
        // setBtn(false);
        // console.log("Se puede guardar")
        setValidationMeta(true)
      }
    };
    validateMetaOfCurrentObjetives();
  }, [data, lastValue, btn, objetivesTypeG]);

  // useEffect(() => {}, [data, lastValue, btn, objetivesTypeG]);

  useEffect(() => {
    if (show) setLoadingPagination(false);    
  }, [show]);


  return (
    <ModalMySelectWrapper display={show}>
      <div className="modalWrapperSelect">
        <div className="modalBody">
          <HeaderModalSelect>
            <div className="titleSelect">Definir objetivos individuales</div>
            <div className="closeModalSelect" onClick={(e) => onClose(e)}>
              <img src={Close} alt="close" style={{ height: "16px" }} />
            </div>
          </HeaderModalSelect>
          <ModalContainerBodySelect>
            <ModalHeadTable>
              <div className="first">Semestres</div>
              <div className="second">Valor</div>
              <div className="third">Descripción</div>
              <div className="fourth">Meta</div>
            </ModalHeadTable>

            <>
              <ColumnsDataD
                objetivesTypeG={objetivesTypeG}
                setObjetivesTypeG={setObjetivesTypeG}
                dataMap={objetivos}
                semestre={semestre}
              />

              {data.map((item, index) => {
                return (
                  <ModalBodyTable borderColor={copyData[index]} key={index}>
                    <div className="first">{semestre}</div>
                    <div className="second">
                      <ContainerInput>
                        <TextField
                          sx={{ maxWidth: "120px" }}
                          value={item.valor === 0 ? "" : item.valor}
                          onChange={(e) => {
                            if (
                              regexValidateNumber.test(e.target.value) ||
                              e.target.value === ""
                            ) {
                              handleChange(index, "valor", e.target.value);
                            }
                          }}
                          name="valor"
                          InputProps={{
                            inputComponent: NumericFormatCustom as any,
                          }}
                          disabled={false}
                        />
                      </ContainerInput>
                      {/* <ModalInputSelect
                        handleChange={handleChange}
                        value={item.valor}
                        disable={false}
                        index={index}
                        name="valor"
                      /> */}
                    </div>
                    <div className="third">
                      <ContainerDescription>
                        {data[index].descripcion.label !==
                          "Selecciona un objetivo" ? (
                          <EditObjetive
                            setEditObjective={setShowEditObjetive}
                            data={data}
                            index={index}
                            setIndexEdit={setIndexEdit}
                          />
                        ) : (
                          <Select
                            options={optionsData}
                            value={item.descripcion}
                            onChange={(value) =>
                              handleChange(
                                index,
                                "descripcion",
                                value as TableData["descripcion"]
                              )
                            }
                          />
                        )}
                      </ContainerDescription>
                    </div>
                    <div className="fourth">
                      <ModalInputSelect
                        handleChange={handleChange}
                        value={item.meta === 0 ? "" : item.meta}
                        disable={false}
                        index={index}
                        name="meta"
                      />
                    </div>
                  </ModalBodyTable>
                );
              })}
              <ColumnsDataG
                dataMap={objetivos}
                semestre={semestre}
                inputValue={lastValue}
                setInputValue={setLastValue}
              />
              <FooterModalMySelect
                validationMeta={validationMeta}
                dataFront={data}
                dataBack={objetivosSum}
                inputValue={lastValue}
                validationSuma={suma}
                objetivesTypeG={objetivesTypeG}
              />
            </>

            <FooterModalSelect>
              <ButtonModalCancel
                onClick={(e) => {
                  onClose(e);
                }}
              >
                Cancelar
              </ButtonModalCancel>
              <ButtonModalSave
                onClick={() => {
                  setShowModalCaution(true);
                }}
                disabled={btn}
              >
                Guardar
              </ButtonModalSave>
            </FooterModalSelect>
          </ModalContainerBodySelect>
        </div>
      </div>
      <>
        {modalEditObjetive && (
          <ModalEditObjective
            values={modalInfo}
            show={modalEditObjetive}
            setValue={setModalInfo}
            setData={setData}
            data={data}
            onHide={setModalEditObjetive}
            indexObjetive={indexObjetive}
            defObj
            handleChangeDos={handleChange}
          />
        )}
        <ModalObjetive
          show={showEditObjetive}
          onHide={setShowEditObjetive}
          handleChangeDos={handleChange}
          data={data}
          indexEdit={indexEdit}
        />
        <Caution
          textBody="La acción de agregar obejtivos a una evaluación es irreversible. "
          textBodyOk=""
          messageShow={true}
          textBodyQuestion="¿Estás seguro de proceder?"
          title="Objetivos individuales"
          estado={showModalCaution}
          cambiarEstado={setShowModalCaution}
          textButtonAct="Continuar"
          textButtonClose="Cancelar"
          handleClick={async () => {
            onSubmit();
            if (evaluation) {
              navigate(
                `/evaluacion/${evaluation.employeeEvaluationEvaluatedUserId}/${evaluation.evaluationPeriod.evaluationPeriodYear}/${evaluation.evaluationPeriod.evaluationPeriodNumberOfYear}`
              );
            }
          }}
        />
      </>
    </ModalMySelectWrapper>
  );
};

export default ModalTable;

const ButtonModalCancel = styled.button`
  background-color: #3b63a2;
  border-radius: 8px;
  font-size: 14px;
  font-family: "Plus Jakarta Sans";
  font-weight: 700;
  color: white;
  padding: 8px 24px;
  cursor: pointer;
  border: none;
`;

export const ButtonModalSave = styled.button`
  background-color: #c9f2ff;
  border-radius: 8px;
  font-size: 14px;
  font-family: "Plus Jakarta Sans";
  font-weight: 700;
  color: #3b63a2;
  padding: 8px 24px;
  /* cursor: pointer; */
  border: none;
`;

const FooterModalSelect = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  gap: 8px;
  /* padding: 14px 21px; */
  margin-top: 20px;
`;

type PropTypeBg = {
  display?: any;
  disabled?: any;
  colorBtn?: any;
};

const ModalMySelectWrapper = styled.div.attrs(
  (props: PropTypeBg) => ({})
) <PropTypeBg>`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: ${(props) => (props.display ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  z-index: 99;
  font-family: "Plus Jakarta Sans";

  .modalWrapperSelect {
    width: 80%;
    max-width: 880px;
    background-color: #ffffff;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;

    height: 600px; /* Ajusta esta altura según tus necesidades */
    overflow-y: auto; /* Agrega un scroll vertical cuando sea necesario */
    position: relative;
    ::-webkit-scrollbar {
      -webkit-appearance: none;
    }
  }

  .modalBody {
    width: 100%;
    padding: 4px;
  }
`;

const HeaderModalSelect = styled.div`
  padding: 20px 0px 16px 0px;
  width: 96%;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #e5e5e5;
  position: sticky;
  margin: 10px auto;

  .titleSelect {
    font-size: 18px;
    font-weight: 700;
    color: #1f2a40;
    display: flex;
    justify-content: start;
    width: 100%;
    gap: 33px;
    /* border: 1px solid red; */
  }

  .closeModalSelect {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    cursor: pointer;
    top: 15px;
    right: 13px;
    /* border: 1px solid red; */
  }
`;

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        allowNegative={false}
        decimalScale={0}
        thousandSeparator
        valueIsNumericString
        prefix=""
        suffix={"%"}
        isAllowed={(values) => {
          const { floatValue, formattedValue } = values;
          //   const MAX_LIMIT = 120;
          if (formattedValue.startsWith('0') && formattedValue.length > 1) {
            return false;
          }
          if (floatValue) {
            return floatValue <= 100;
          }
          return true;
        }}
      />
    );
  }
);

function ModalInputSelect({ handleChange, value, disable, index, name }: any) {
  return (
    <ContainerInput>
      <TextField
        sx={{ maxWidth: "120px" }}
        value={value}
        onChange={(e) => {
          if (
            regexValidateNumber.test(e.target.value) ||
            e.target.value === ""
          ) {
            handleChange(index, name, e.target.value);
          }
        }}
        name={name}
        InputProps={{
          inputComponent: NumericFormatCustom as any,
        }}
        disabled={disable}
      />
    </ContainerInput>
  );
}

const ContainerInput = styled.div`
  /* display: flex;
    align-items: center;
    justify-content: center;
    align-content: center; */
  /* border: 1px solid red; */
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    box-sizing: inherit;
    max-height: 40px;
    border-radius: 8px;
    width: 100%;
    max-width: 100px;
    outline: none;
    font-family: Plus Jakarta Sans;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    width: 85%;
    margin: 0 auto;
    outline: none;
    font-family: Plus Jakarta Sans;
  }
  .css-1xysdry-MuiFormControl-root-MuiTextField-root {
    display: flex;
    font-family: Plus Jakarta Sans;
    align-items: center;
    justify-content: center;
    outline: none;
    align-content: center;
  }

  .MuiInputBase-root:focus {
    outline: none;
    font-family: Plus Jakarta Sans;
  }

  .MuiInputBase-root {
    outline: none;
    font-family: Plus Jakarta Sans;
  }
`;
